const isDevelopment =
  process.env.REACT_APP_ENVIRONMENT === 'development' || process.env.NODE_ENV === 'development';

export const tourDestinationPathnameMap = {
  'gumbalde-resort': isDevelopment ? 1044 : 938,
  'allerum-golf-club': isDevelopment ? 1619 : 829,
  'laholms-golf-club': isDevelopment ? 1066 : 1982,
  'landeryd-golf-club': isDevelopment ? 1080 : 1983,
  'forsbacka-golf-club': isDevelopment ? 1044 : 995,
  'isaberg-golf-club': isDevelopment ? 1619 : 911,
  'malaro-golf-club': isDevelopment ? 1066 : 1033,
  'sjobo-golf-club': isDevelopment ? 1080 : 840,
  'varberg-golf-club': isDevelopment ? 1044 : 2095,
  'solvesborg-golf-club': isDevelopment ? 1619 : 935,
} as const;

export type TourDestinationPathname = keyof typeof tourDestinationPathnameMap;

export const tourDestinationPathnames = Object.keys(
  tourDestinationPathnameMap
) as Array<TourDestinationPathname>;
