import { FC, PropsWithChildren, ReactNode, useRef, useState } from 'react';
import { Tooltip as BaseTooltip, TooltipProps as BaseTooltipProps } from 'reactstrap';

interface TooltipProps
  extends Partial<Pick<BaseTooltipProps, 'isOpen' | 'target' | 'popperClassName'>> {
  title: ReactNode;
  targetClassname?: string;
}

const Tooltip: FC<PropsWithChildren<TooltipProps>> = props => {
  const { children, title, target, targetClassname, ...rest } = props;
  const tooltipRef = useRef(null);
  const [isOpened, setIsOpened] = useState(false);

  return (
    <>
      {target ? (
        children
      ) : (
        <span ref={tooltipRef} className={targetClassname}>
          {children}
        </span>
      )}
      <BaseTooltip
        fade={false}
        isOpen={isOpened}
        target={target || tooltipRef}
        toggle={() => setIsOpened(value => !value)}
        {...rest}
      >
        {title}
      </BaseTooltip>
    </>
  );
};

export default Tooltip;
