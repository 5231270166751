import React, { FC, HTMLAttributes } from 'react';
import { Badge } from 'reactstrap';

import classNames from '../../helpers/classNames';
import useTranslate from '../../hooks/useTranslate';

interface GolfCourseBadgeProps extends HTMLAttributes<HTMLDivElement> {
  topBooked?: boolean;
  topRated?: boolean;
}

const GolfCourseBadge: FC<GolfCourseBadgeProps> = props => {
  const { topRated = false, topBooked = false, className, style, ...rest } = props;
  const { translate } = useTranslate();

  return (
    <div
      className={classNames(className, 'd-flex flex-column align-items-end gap-2 p-2')}
      style={{ zIndex: 1, ...style }}
      {...rest}
    >
      {topBooked && (
        <Badge className={'text-dark fs-6'} color={'light'} pill>
          {translate('golfCourseBadge.topBooked')}
        </Badge>
      )}
      {topRated && (
        <Badge className={'text-dark fs-6'} color={'light'} pill>
          {translate('golfCourseBadge.topRated')}
        </Badge>
      )}
    </div>
  );
};

export default GolfCourseBadge;
