import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import usePopularGolfCourses from '../../hooks/usePopularGolfCourses';
import useTranslate from '../../hooks/useTranslate';
import { MappedGolfCourseType } from '../../services/apis/golfCourses/types';
import GolfCourseCard from '../GolfCourseCard/GolfCourseCard';
import ScrollSnapSlider from '../ScrollSnapSlider/ScrollSnapSlider';

interface GolfCoursesCardsProps {
  golfCourses: MappedGolfCourseType[];
  url: string;
  isAccommodation?: boolean;
}

const GolfCoursesCards: FC<GolfCoursesCardsProps> = props => {
  const { translate } = useTranslate();
  const { golfCourses, url, isAccommodation } = props;
  const popularGolfCourses = usePopularGolfCourses();

  return (
    <ScrollSnapSlider
      footer={
        <Link to={url}>
          {isAccommodation ? translate('golfCamping.showAll') : translate('default.showAllCourses')}
        </Link>
      }
    >
      {golfCourses.map(golfCourse => (
        <GolfCourseCard
          key={golfCourse.id}
          golfCourse={golfCourse}
          isAccommodation={isAccommodation}
          responsive
          topRated={popularGolfCourses
            .find(item => item.country.id === golfCourse.country.id)
            ?.topRated.some(({ golfCourse: { id } }) => id === golfCourse.golfCourse.id)}
          topBooked={popularGolfCourses
            .find(item => item.country.id === golfCourse.country.id)
            ?.topBooked.some(({ golfCourse: { id } }) => id === golfCourse.golfCourse.id)}
        />
      ))}
    </ScrollSnapSlider>
  );
};

export default GolfCoursesCards;
