import React, {
  FC,
  MouseEventHandler,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import IconLeft from 'material-react-icons/KeyboardArrowLeft';
import IconRight from 'material-react-icons/KeyboardArrowRight';

import Button from '../Button/Button';
import styles from './ScrollSnapSlider.module.scss';

interface ScrollSnapSliderProps {
  footer?: ReactNode;
}

const ScrollSnapSlider: FC<PropsWithChildren<ScrollSnapSliderProps>> = ({ children, footer }) => {
  const sliderElementRef = useRef<HTMLDivElement>(null);
  const controlClickHandler = useCallback<MouseEventHandler<HTMLButtonElement>>(e => {
    const sliderElement = sliderElementRef.current;

    if (!sliderElement) return;

    const button = e.currentTarget;
    const { direction } = button.dataset;
    const itemWidth = sliderElement.children.item(0)?.clientWidth || sliderElement.clientWidth;

    sliderElementRef.current.scrollBy({
      left: itemWidth * (direction === 'forward' ? 1 : -1),
      behavior: 'smooth',
    });
  }, []);
  const [hasScroll, setHasScroll] = useState(false);

  useLayoutEffect(() => {
    if (!sliderElementRef.current) return;

    const updateHasScroll = () => {
      if (sliderElementRef.current) {
        setHasScroll(sliderElementRef.current.scrollWidth > sliderElementRef.current.clientWidth);
      }
    };

    updateHasScroll();

    const observer = new ResizeObserver(updateHasScroll);
    observer.observe(sliderElementRef.current);

    sliderElementRef.current.querySelectorAll('img').forEach(media => {
      media.addEventListener('load', updateHasScroll);
      media.addEventListener('error', updateHasScroll);
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div>
      <div ref={sliderElementRef} className={styles.slider}>
        {children}
      </div>
      <div className={styles.footer}>
        {footer}
        {hasScroll && (
          <div className={styles.controls}>
            <Button primary data-direction="backward" onClick={controlClickHandler}>
              <IconLeft />
            </Button>
            <Button primary data-direction="forward" onClick={controlClickHandler}>
              <IconRight />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ScrollSnapSlider;
