import React, { FC, useRef } from 'react';
import { Col, Modal, ModalBody, Row } from 'reactstrap';

import onTeeLogo from '../../assets/img/logotype.png';
import useTranslate from '../../hooks/useTranslate';
import { useBenefits } from '../Benefits/useBenefits';
import Tooltip from '../Tooltip/Tooltip';
import styles from './UniqueSellingPointsPopup.module.scss';

interface UniqueSellingPointsPopupProps {
  onClose: () => void;
}
const UniqueSellingPointsPopup: FC<UniqueSellingPointsPopupProps> = props => {
  const { onClose } = props;
  const { translate } = useTranslate();
  const textContentRef = useRef(new Map<string, HTMLElement>());
  const benefits = useBenefits();

  return (
    <Modal
      isOpen
      fade={false}
      toggle={onClose}
      size="lg"
      className="border-0"
      contentClassName={styles.body}
      centered
    >
      <button
        type="button"
        onClick={onClose}
        className="btn-close position-absolute top-0 end-0 p-3"
        style={{ zIndex: 1 }}
      />
      <ModalBody className={'py-5 px-5 fw-bold text-center text-sm-start'}>
        <img src={onTeeLogo} height="80" className="d-block mx-auto" alt="OnTee logo" />

        <Row className={'mt-5'}>
          <Col xs={12} md={7} className={'pt-2'}>
            <p className={'h2'}>{translate('uniqueSellingPointsPopup.title')}</p>
            <p className={'h4'}>{translate('uniqueSellingPointsPopup.subtitle')}</p>
            <div className="d-flex flex-column gap-3 my-5">
              {benefits.map(({ img, alt, text, tooltip }, index) => {
                const id = `item-${index + 1}`;

                return (
                  <div
                    key={id}
                    className="d-flex align-items-center gap-3 flex-column flex-sm-row w-100"
                  >
                    <img src={img} alt={alt} width={100} height={100} className="flex-shrink-0" />

                    <div
                      ref={instance => {
                        if (instance) {
                          textContentRef.current.set(id, instance);
                        } else {
                          textContentRef.current.delete(id);
                        }
                      }}
                    >
                      {tooltip ? (
                        <Tooltip title={tooltip} target={textContentRef.current.get(id)}>
                          {text}
                        </Tooltip>
                      ) : (
                        text
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default UniqueSellingPointsPopup;
