import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { differenceInDays, parseISO } from 'date-fns';

import { formatISODate } from '../helpers/formatISODate';
import { useOrder } from '../services/apis/order/api';
import useStore from '../store/useStore';
import { useExitIntent } from './useExitIntent';

const DAYS_THRESHOLD = 30;
const HISTORY_STACK_THRESHOLD = 2;

const useUniqueSellingPointsPopup = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { isLoggedIn, uniqueSellingPointsPopupLastShown, setUniqueSellingPointsPopupLastShown } =
    useStore(state => state);
  const { data: order } = useOrder();
  const hasOrderItems = useMemo(() => !!order?.orderItems?.length, [order]);
  const visitedCoursePagesStack = useRef(new Set<string>());
  const location = useLocation();

  const hide = useCallback(() => {
    setIsVisible(false);
  }, []);

  useEffect(() => {
    if (
      matchPath('/:locale/booking/:clubId/:courseId', location.pathname) &&
      !matchPath('/:locale/booking/accommodation/', location.pathname)
    ) {
      visitedCoursePagesStack.current.add(location.pathname);
    }
  }, [location.pathname]);

  useExitIntent(
    useCallback(() => {
      if (isVisible || !isLoggedIn) return false;

      const canShowPopup = () => {
        const canShow =
          hasOrderItems || visitedCoursePagesStack.current.size > HISTORY_STACK_THRESHOLD;

        if (!canShow) return false;

        if (!uniqueSellingPointsPopupLastShown) return true;

        // Show popup once every N days
        return (
          differenceInDays(new Date(), parseISO(uniqueSellingPointsPopupLastShown)) > DAYS_THRESHOLD
        );
      };

      if (canShowPopup()) {
        setIsVisible(true);
        setUniqueSellingPointsPopupLastShown(formatISODate(new Date()));
      }
    }, [
      isVisible,
      isLoggedIn,
      hasOrderItems,
      uniqueSellingPointsPopupLastShown,
      setUniqueSellingPointsPopupLastShown,
    ])
  );

  return useMemo(() => ({ isVisible, hide }), [isVisible, hide]);
};

export default useUniqueSellingPointsPopup;
