import React, { FC, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';

import IconLocationOn from 'material-react-icons/LocationOn';

import classNames from '../../helpers/classNames';
import { ACCOMMODATION_PARAM, ACCOMMODATION_VALUE } from '../../hooks/useAccommodationFilter';
import useTranslate from '../../hooks/useTranslate';
import getGolfClubInfo from '../../services/apis/golfCourses/getGolfClubInfo';
import getGolfCourseUrl from '../../services/apis/golfCourses/getGolfCourseUrl';
import { MappedGolfCourseType } from '../../services/apis/golfCourses/types';
import Button from '../Button/Button';
import GolfCourseBadge from '../GolfCourseBadge/GolfCourseBadge';
import LazyImg from '../LazyImg/LazyImg';
import styles from './GolfCourseCard.module.scss';

interface GolfCourseCardProps {
  golfCourse: MappedGolfCourseType;
  isAccommodation?: boolean;
  responsive?: boolean;
  topRated?: boolean;
  topBooked?: boolean;
}

const GolfCourseCard: FC<GolfCourseCardProps> = props => {
  const { golfCourse, isAccommodation, topRated = false, topBooked = false, responsive } = props;
  const info = getGolfClubInfo(golfCourse);
  const { translate, activeLanguage } = useTranslate();
  const location = useLocation();
  const search = useMemo(() => {
    const params = new URLSearchParams(location.search);

    if (isAccommodation) {
      params.set(ACCOMMODATION_PARAM, ACCOMMODATION_VALUE);
    }

    return `?${params.toString()}`;
  }, [isAccommodation, location.search]);

  return (
    <Link
      to={{
        pathname: `/${activeLanguage.code}/booking/${getGolfCourseUrl(golfCourse)}`,
        search,
      }}
      className={classNames(styles.container, responsive && styles.responsive)}
    >
      <div className={styles.imageWrapper}>
        <LazyImg className={styles.image} src={info?.image} alt={golfCourse.name} />
        <GolfCourseBadge
          className={'position-absolute top-0 end-0'}
          topRated={topRated}
          topBooked={topBooked}
        />
      </div>

      <div className={styles.body}>
        <h3 className={styles.title}>{golfCourse.golfClub.name}</h3>
        <h4 className={styles.subtitle}>{golfCourse.name}</h4>
        <div className={styles.location}>
          <IconLocationOn size={20} />
          {golfCourse.country.name}
        </div>
      </div>

      <div className={styles.footer}>
        <Button primary>{translate('default.book')}</Button>
      </div>
    </Link>
  );
};

export default GolfCourseCard;
