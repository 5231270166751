import { useMemo } from 'react';

import { sortBy, uniq } from 'lodash-es';

import useCountries from '../services/apis/countries/api';
import { CountryType } from '../services/apis/countries/types';
import useGolfCourses from '../services/apis/golfCourses/api';
import getGolfClubInfo from '../services/apis/golfCourses/getGolfClubInfo';
import { MappedGolfCourseType } from '../services/apis/golfCourses/types';
import useStatistics from '../services/apis/statistics';

const MAX_ITEMS = 5;

const STATISTICS_FILTER = {
  pt: { country: 'pt' },
  es: { country: 'es' },
  it: { country: 'it' },
};

type PopularGolfCourseType = {
  country: CountryType;
  topBooked: Array<MappedGolfCourseType>;
  topRated: Array<MappedGolfCourseType>;
};

const usePopularGolfCourses = (): Array<PopularGolfCourseType> => {
  const countries = useCountries();
  const { data: allCourses } = useGolfCourses();
  const { data: portugalStatisticsData } = useStatistics(STATISTICS_FILTER['pt']);
  const { data: italyStatisticsData } = useStatistics(STATISTICS_FILTER['it']);
  const { data: spainStatisticsData } = useStatistics(STATISTICS_FILTER['es']);

  return useMemo(() => {
    const data = new Map<CountryType['id'], PopularGolfCourseType>();

    // top-rated
    countries.forEach(country => {
      const allCountryCourses =
        allCourses?.filter(course => course.country.id === country.id) ?? [];

      const golfhaftetRated = sortBy(
        allCountryCourses?.filter(course => !!getGolfClubInfo(course)?.golfhaftetRating?.rating),
        course => getGolfClubInfo(course)?.golfhaftetRating?.rating || 0
      ).reverse();
      const googleRated = sortBy(
        allCountryCourses?.filter(course => !!getGolfClubInfo(course)?.googleRating?.rating),
        course => getGolfClubInfo(course)?.googleRating?.rating || 0
      ).reverse();
      const topRated: PopularGolfCourseType['topRated'] = [...golfhaftetRated, ...googleRated];

      // top booked
      const topBooked: PopularGolfCourseType['topBooked'] = [];

      for (const [countryCode, data] of [
        ['pt', portugalStatisticsData],
        ['it', italyStatisticsData],
        ['es', spainStatisticsData],
      ]) {
        if (country.code?.toLowerCase() === countryCode) {
          const mostBookedLastMonth = data
            ? (data.mostBookedGolfclubsLastMonth as Array<{
                id: number;
              }>)
            : [];

          mostBookedLastMonth.forEach(golfClub => {
            const clubCourses = allCountryCourses.filter(
              course => golfClub.id === course.golfClub.id
            );

            topBooked.push(...clubCourses);
          });
        }
      }

      data.set(country.id, {
        country,
        topBooked: uniq(topBooked).slice(0, MAX_ITEMS),
        topRated: uniq(topRated).slice(0, MAX_ITEMS),
      });
    });

    return Array.from(data.values());
  }, [allCourses, countries, italyStatisticsData, portugalStatisticsData, spainStatisticsData]);
};

export default usePopularGolfCourses;
